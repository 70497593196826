<template>
  <div class="constructor-layout" id="constructor" v-show="true">
    <div class="added-alert">
      <div class="bb-container bb-container--lista">
        <div class="added-alert__center">
          <portal-target name="alerts_constructor_page"></portal-target>
        </div>
      </div>
    </div>

    <!-- Sección app download //////////// -->
    <section class="constructor-layout__app" v-if="true">
      <mq-layout :mq="['m480', 'm576', 'm768', 'm992']">
        <banner-download-mobile-apps></banner-download-mobile-apps>
      </mq-layout>
    </section>

    <!-- Sección Constructor //////////// -->
    <section class="constructor-layout__sections">
      <div class="bb-container bb-container--lista">
        <div :class="countryCode === 'ES' ? ['constructor-layout-simple__content'] : []">

          <Notification
            v-if="showNotification"
            tipo="error"
            @close-notification="closeNotification()"
          >
            {{ errorMessage }}
          </Notification>

          <mq-layout :mq="['m1200']">
            <BannerDownloadMobileAppsDesktop
              @close="closeMobileAppDesktopBanner()"
              :isVisible="mobileAppDesktopBannerIsVisible"
            />

            <div class="constructor-layout__main-banner">
              <CtaExtensionBanner
                @close="closeExtensionBanner()"
                :isVisible="extensionBannerIsVisible"
              />
            </div>
          </mq-layout>

          <!-- Main Banner -->
          <div class="constructor-layout__main-banner">
            <!-- <CtaMainBanner v-if="hasProducts"></CtaMainBanner> -->
            <CtaMainBannerSm
              parentPage="Constructor - Banner Horitzontal"
              v-if="hasProducts && (ownerListType === 'baby-registry' || ownerListType === 'babyshower')"
            ></CtaMainBannerSm>
          </div>

          <!-- TODO: NEXTGEN Search -->
          <div class="constructor-layout__search" :class="countryCode === 'ES' ? ['constructor-layout__search--compact'] : ''">
            <SearchConstructor v-if="ownerListType === 'baby-registry' || ownerListType === 'babyshower'"></SearchConstructor>
            <search-constructor-international v-else></search-constructor-international>
          </div>

          <!-- <add-free-product /> -->
          <!-- <fund-add /> -->

          <div class="constructor-layout-simple__howto" v-show="countryCode === 'ES'">
            <div class="search-hiw">
              <div class="search-hiw__col">
                <mq-layout mq="m992+">
                  <picture class="modal-hiw__picture">
                    <img
                      src="../../assets/img/covers/cover-elige.jpg"
                      alt="¿Cómo funciona?"
                    />
                  </picture>
                </mq-layout>
              </div>
              <div class="search-hiw__col">
                <header class="search-hiw__title">¿Cómo funciona?</header>
                <!-- <mq-layout mq="m992+">
                  <div class="search-hiw__subtitle">
                    Guarda cosas en tu lista de HelloBB así:
                  </div>
                </mq-layout> -->
                <main class="modal-hiw__items">
                  <div class="modal-hiw__item">
                    <div class="modal-hiw__step">
                      <div class="modal-hiw__cercle">1</div>
                    </div>
                    <div class="modal-hiw__desc">
                      Busca en la web de cualquier tienda algo que te guste.
                    </div>
                  </div>
                  <div class="modal-hiw__item">
                    <div class="modal-hiw__step">
                      <div class="modal-hiw__cercle">2</div>
                    </div>
                    <div class="modal-hiw__desc">Copia su enlace.</div>
                  </div>
                  <div class="modal-hiw__item">
                    <div class="modal-hiw__step">
                      <div class="modal-hiw__cercle">3</div>
                    </div>
                    <div class="modal-hiw__desc">Pégalo en el formulario de arriba.</div>
                  </div>
                </main>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

    <section class="constructor-layout__constructor" v-show="countryCode !== 'ES'">
      <div class="bb-container bb-container--lista">
        <!-- Main Banner -->
        <header class="constructor-layout__header">
          <header-constructor />
        </header>

        <!-- Explore -->
        <main class="constructor-layout__explore">
          <explore></explore>
        </main>
      </div>

      <FirstConstructorModal
        v-if="showFirstFullConstructorModal"
        @close="closeModal()"
      ></FirstConstructorModal>
    </section>

  </div>
</template>

<script>
import CtaMainBanner from "./components/CtaMainBanner.vue";
import CtaMainBannerSm from "./components/CtaMainBannerSm.vue";
import CtaExtensionBanner from "./components/CtaExtensionBanner.vue";
import BannerDownloadMobileApps from "./components/BannerDownloadMobileApps";
import BannerDownloadMobileAppsDesktop from "./components/BannerDownloadMobileAppsDesktop";
import HeaderConstructor from "./components/HeaderConstructor";
import AddFreeProduct from "./components/AddFreeProduct";
import FundAdd from "./components/FundAdd";
import Explore from "./components/Explore";
import CanGetOwnerList from "../../Mixins/CanGetOwnerList";
import { mapGetters } from "vuex";
import Alert from "../../components/ui/Alert.vue";

// Nou Search
import SearchConstructor from "./components/SearchConstructor";
import SearchConstructorInternational from "./components/SearchConstructorInternational";
import SearchConstructorResultados from "./components/SearchConstructorResultados";
import Notification from "../../components/ui/Notification.vue";

import FirstConstructorModal from "./components/FirstConstructorModal.vue";
import ConstructorCommon from "./mixins/ConstructorCommon";

export default {
  name: "ConstructorPage",
  mixins: [
    CanGetOwnerList,
    ConstructorCommon,
  ],
  components: {
    Notification,
    BannerDownloadMobileApps,
    BannerDownloadMobileAppsDesktop,
    HeaderConstructor,
    AddFreeProduct,
    FundAdd,
    Explore,
    CtaMainBanner,
    CtaMainBannerSm,
    CtaExtensionBanner,
    Alert,
    SearchConstructor,
    SearchConstructorInternational,
    SearchConstructorResultados,
    FirstConstructorModal,
  },
  metaInfo() {
    return {
      title: "Construye tu lista | HelloBB",
    };
  },
  data() {
    return {
      show: false,
      error: this.$route.params.error || false,
      errorMessage: this.$route.params.message || "",
      showNotification: this.$route.params.message || "",
      showFirstFullConstructorModal: false,
    };
  },
  computed: {
    ...mapGetters({
      listConstructor: "listConstructor",
      limit: "limit",
      countryCode: "countryCode",
      ownerListType: "ownerListType",
    }),
    hasProducts() {
      if (this.list) {
        if (this.list.products) {
          return this.list.products.length > 0;
        }
      }
      return false;
    },
  },
  async serverPrefetch() {
    //await this.$store.dispatch("getListConstructor");
    //console.log("serverPrefetch", this.$store.state.listConstructor)
  },
  async mounted() {
    console.log("mounted", this.$store.state.limit);
    console.log("mounted", this.$store.state.listConstructor);
    await this.$store.dispatch("setLimitSectionsToShow");
    // if (this.$utils.isEmpty(this.listConstructor)) {
    //   await this.$store.dispatch("getListConstructor");
    // }
    await this.$store.dispatch("getListConstructor");
    this.show = true;
    this.$store.commit("setCanScroll");

    const modalShownCookie = this.getCookie("modalShown");
    console.log({ modalShownCookie });
    console.log({ result: modalShownCookie && modalShownCookie !== "undefined" });
    if (modalShownCookie && modalShownCookie !== "undefined") {
      this.showFirstFullConstructorModal = true;
    }
  },
  methods: {
    closeNotification() {
      this.errorMessage = "";
      this.showNotification = false;
    },
    closeModal() {
      this.showFirstFullConstructorModal = false;
      this.removeCookie("modalShown");
    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) {
        return parts.pop().split(";").shift();
      }
    },
    removeCookie(name) {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    },
  },
  created() {
    if (this.error) {
      this.showNotification = true;
    }
  },
};
</script>
